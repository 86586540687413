<template>
  <section
    class="configure-document"
    id="drag-template-background"
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- v-if="getCompanyDocumentDataById" -->
    <div class="inner-navbar">
      <div class="d-flex">
        <div>
          <el-popconfirm
            title="Are you sure to Exit?"
            @confirm="backToDashCompanyDocuments()"
            confirm-button-text="Yes"
            cancel-button-text="No"
          >
            <el-button
              slot="reference"
              class="rounded-0 back-btn type-2"
              type="primary"
              ><i class="el-icon-back"></i
            ></el-button>
          </el-popconfirm>
        </div>
        <div class="flex-fill left-block vue-form ml-2">
          <el-form class="form-title">
            <el-input
              v-model="documentName"
              placeholder="Enter Template Title"
            ></el-input>
            <p
              class="error"
              v-if="
                getConfigurableDocumentErrors &&
                  getConfigurableDocumentErrors.name
              "
            >
              {{ getConfigurableDocumentErrors.name }}
            </p>
          </el-form>
        </div>

        <div class="right-block flex-self-end pe-3 align-items-self">
          <el-badge
            :value="documentRecipientsList.length"
            class="item ml-2"
            type="danger"
          >
            <el-button type="danger" @click="addDocumentUsers = true" plain
              >Add Recipient</el-button
            >
          </el-badge>
          <el-button type="primary" class="type-2 ml-2" @click="fillAndSend()"
            >Create Document</el-button
          >

          <el-button
            type="primary"
            class="type-2 ml-2"
            @click="saveTemplate(true)"
            >Save Template</el-button
          >
          <!-- <el-button type="primary" class="type-2 ml-2" @click="fillAndSend()">
            Save as Draft
          </el-button>
          <el-button type="primary" class="type-2 ml-2" @click="sendWithSubmit()">
            Send 
          </el-button> -->
        </div>
      </div>
    </div>

    <el-dialog
      title="Save As Template ?"
      :visible.sync="isTemplateToSave"
      width="30%"
      center
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form ref="form" :model="isTemplateForm">
        <el-form-item
          label="Yes! Save it as template."
          class="mb-0"
          v-if="!this.getConfigurableDocumentDataById.is_template"
        >
          <el-switch v-model="isTemplate"></el-switch>
        </el-form-item>
        <el-form-item label="Document Title" class="mb-0">
          <el-input v-model="templateTitle"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isTemplateToSave = false">Cancel</el-button>
        <el-button type="success" @click="saveData()">Confirm</el-button>
      </span>
    </el-dialog>

    <div class="document-editor-container">
      <!-- <el-col :span="16" style="width:640px;"> -->
      <el-scrollbar v-loading="documentDataLoading">
        <div class="form-builder" ref="formBuilder">
          <div class="form-holder">
            <div class="form-image-holder">
              <img
                v-for="(image, index) of backgroundImages"
                :key="index"
                :src="getBackgroundImage(image)"
                class="page-image"
              />
            </div>
            <div
              class="form-fields-holder"
              v-loading="loadingElements"
              v-if="false"
            >
              <VueDraggableResizable
                v-for="(item, index) of elements"
                :key="index"
                class-name-active="my-active-class"
                :class="
                  'each-element ' +
                    item.filled_by +
                    ' ' +
                    contentFields.indexOf(item.type) !=
                  -1
                    ? 'content-fields'
                    : ''
                "
                @dragging="(x, y) => onDrag(x, y, item)"
                @resizing="
                  (x, y, width, height) => onResize(x, y, width, height, item)
                "
                :parent="true"
                :h="item.height"
                :w="item.width"
                :x="item.x"
                :y="item.y"
                :style="getColorByFilledBy(item)"
                @keyup.delete="$emit('onDelete')"
                tabindex="0"
                @activated="onActivated(item, index)"
                :handles="getHandleByType(item.type)"
                :data-color="getFilledUserColor(item)"
              >
                <!-- @dblclick.native="openPopup(item, index)" -->
                <!-- @dblclick="openPopup(item, index)" -->
                <a style="cursor: pointer">
                  <!-- Table Preview -->
                  <el-table
                    v-if="
                      item.section == 'table' &&
                        item.headers &&
                        item.headers.length &&
                        !tableElementLoading
                    "
                    :data="item.data"
                  >
                    <el-table-column type="index" width="30"></el-table-column>
                    <el-table-column
                      v-for="(header, index) of item.headers"
                      :key="index"
                    >
                      <template slot="header">
                        {{ header }}
                      </template>
                      <template slot-scope="scope">
                        {{ item.data[scope.$index][header] }}
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- Heading Preview -->
                  <div v-else-if="item.type == 'HEADING' && item.content">
                    <h1 v-if="item.selected_tag == 'h1'">
                      {{ item.content }}
                    </h1>
                    <h2 v-if="item.selected_tag == 'h2'">
                      {{ item.content }}
                    </h2>
                    <h3 v-if="item.selected_tag == 'h3'">
                      {{ item.content }}
                    </h3>
                    <h4 v-if="item.selected_tag == 'h4'">
                      {{ item.content }}
                    </h4>
                    <h5 v-if="item.selected_tag == 'h5'">
                      {{ item.content }}
                    </h5>
                    <h5 v-if="item.selected_tag == 'h6'">
                      {{ item.content }}
                    </h5>
                  </div>
                  <!-- Paragraph Preview -->
                  <p v-else-if="item.type == 'PARAGRAPH' && item.content">
                    {{ item.content }}
                  </p>
                  <!-- Logo Preview -->
                  <img
                    v-else-if="item.type == 'logo'"
                    :width="item.width"
                    :height="item.height"
                    :src="getLogoUrl"
                  />
                  <img
                    v-else-if="item.type == 'my-signature' && item.source"
                    :width="item.width"
                    :height="item.height"
                    :src="item.source"
                  />
                  <img
                    v-else-if="item.type == 'INITIAL' && item.value"
                    :src="item.value"
                    :width="item.width"
                    :height="item.height"
                  />
                  <!-- Primary fields preview -->
                  <p v-else-if="item.type == 'PRIMARY_FIELDS' && item.content">
                    {{ item.content }}
                  </p>
                  <!-- Default Preview -->
                  <span v-else>{{
                    item.type != "CHECKBOX"
                      ? item.label ||
                        (item.field_type == "TEMPLATE_FIELD"
                          ? item.template_field_label
                          : item.entity_field_label) ||
                        item.type
                      : "+"
                  }}</span>
                </a>
              </VueDraggableResizable>
            </div>
            <div class="form-fields-holder" v-loading="loadingElements">
              <div
                v-for="(item, index) of this.elements"
                :key="index"
                :data-index="index"
              >
                <!-- Heading Preview -->
                <p :style="getStyle(item)" v-if="item.type == 'PRIMARY_FIELDS'">
                  {{ item.content }}
                </p>
                <div :style="getStyle(item)" v-else-if="item.type == 'HEADING'">
                  <h1 v-if="item.selected_tag == 'h1'">
                    {{ item.content }}
                  </h1>
                  <h2 v-if="item.selected_tag == 'h2'">
                    {{ item.content }}
                  </h2>
                  <h3 v-if="item.selected_tag == 'h3'">
                    {{ item.content }}
                  </h3>
                  <h4 v-if="item.selected_tag == 'h4'">
                    {{ item.content }}
                  </h4>
                  <h5 v-if="item.selected_tag == 'h5'">
                    {{ item.content }}
                  </h5>
                  <h5 v-if="item.selected_tag == 'h6'">
                    {{ item.content }}
                  </h5>
                </div>

                <!-- Paragraph Preview -->
                <p :style="getStyle(item)" v-else-if="item.type == 'PARAGRAPH'">
                  {{ item.content }}
                </p>
                <img
                  v-else-if="item.type == 'INITIAL'"
                  :src="item.value"
                  :style="getStyle(item)"
                />
                <img
                  v-else-if="item.type == 'my-signature'"
                  :src="item.source"
                  :style="getStyle(item)"
                />

                <el-input
                  :style="getStyle(item)"
                  type="text"
                  :placeholder="item.placeholder"
                  v-else-if="item.type == 'SINGLE_LINE_TEXT'"
                  v-model="item.value"
                  :disabled="checkFieldAllow(item)"
                  :ref="removeHashesFromKey(item.key)"
                  :class="currentEnabledFieldKey == item.key ? 'active' : ''"
                ></el-input>
                <el-input
                  :style="getStyle(item)"
                  type="text"
                  :placeholder="item.placeholder"
                  v-else-if="item.type == 'NUMBER'"
                  v-model="item.value"
                  :disabled="checkFieldAllow(item)"
                  :ref="removeHashesFromKey(item.key)"
                  :class="currentEnabledFieldKey == item.key ? 'active' : ''"
                ></el-input>

                <el-date-picker
                  :height="item.height"
                  :width="item.width"
                  :style="getStyle(item)"
                  type="date"
                  :placeholder="item.placeholder"
                  v-else-if="item.type == 'DATE'"
                  v-model="item.value"
                  :disabled="checkFieldAllow(item)"
                  :ref="removeHashesFromKey(item.key)"
                  :class="currentEnabledFieldKey == item.key ? 'active' : ''"
                ></el-date-picker>

                <el-select
                  :height="item.height"
                  :width="item.width"
                  :style="getStyle(item)"
                  v-else-if="item.type == 'SELECT'"
                  v-model="item.value"
                  :placeholder="item.placeholder"
                  :disabled="checkFieldAllow(item)"
                  :ref="removeHashesFromKey(item.key)"
                  :class="currentEnabledFieldKey == item.key ? 'active' : ''"
                >
                  <el-option
                    v-for="(option, index) of item.options"
                    :key="index"
                    :value="option"
                    :label="option"
                  ></el-option>
                </el-select>

                <el-select
                  :height="item.height"
                  :width="item.width"
                  :style="getStyle(item)"
                  v-else-if="item.type == 'MULTI_SELECT'"
                  v-model="item.value"
                  :placeholder="item.placeholder"
                  :disabled="checkFieldAllow(item)"
                  multiple
                  :ref="removeHashesFromKey(item.key)"
                  :class="currentEnabledFieldKey == item.key ? 'active' : ''"
                >
                  <el-option
                    v-for="(option, index) of item.options"
                    :key="index"
                    :value="option"
                    :label="option"
                  ></el-option>
                </el-select>

                <input
                  type="checkbox"
                  :height="item.height"
                  :width="item.width"
                  :style="getStyle(item)"
                  v-else-if="item.type == 'CHECKBOX'"
                  v-model="item.value"
                  :disabled="checkFieldAllow(item)"
                  :ref="removeHashesFromKey(item.key)"
                  :class="currentEnabledFieldKey == item.key ? 'active' : ''"
                />
                <el-input
                  type="textarea"
                  :rows="item.rows"
                  :style="getStyle(item)"
                  v-else-if="item.type == 'MULTI_LINE_TEXT'"
                  v-model="item.value"
                  :placeholder="item.placeholder"
                  :disabled="checkFieldAllow(item)"
                  :ref="removeHashesFromKey(item.key)"
                  :class="currentEnabledFieldKey == item.key ? 'active' : ''"
                />

                <!-- @click="captureSignature(item, index)" -->
                <a
                  v-else-if="item.type == 'SIGNATURE' && !item.source"
                  :style="getStyle(item)"
                  disabled="true"
                  :ref="removeHashesFromKey(item.key)"
                >
                  {{ item.label }}
                  <i class="el-icon-edit"></i>
                </a>
                <img
                  v-else-if="item.type == 'SIGNATURE' && item.source"
                  :width="item.width"
                  :height="item.height"
                  :src="item.source"
                  :style="getStyle(item)"
                  @click="captureSignature(item, index)"
                />

                <div :style="getStyle(item)" v-else>
                  <el-table
                    v-if="
                      item.section == 'table' &&
                        item.headers &&
                        item.headers.length &&
                        !tableElementLoading
                    "
                    :data="item.data"
                  >
                    <el-table-column type="index" width="30"></el-table-column>
                    <el-table-column
                      v-for="(header, index) of item.headers"
                      :key="index"
                    >
                      <template slot="header">
                        {{ header }}
                      </template>
                      <template slot-scope="scope">
                        {{ item.data[scope.$index][header] }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <el-dialog
      :visible.sync="addItemDataDialogVisible"
      top="5vh"
      width="45%"
      title="Field Properties"
      class="fields-dialog"
       :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-if="selectedItem.section == 'display'">
        <div v-if="selectedItem.type == 'INITIAL'">
          <el-input
            type="text"
            v-model="selectedItem.text"
            label="Initial"
            placeholder="Enter initial"
          ></el-input>
          <el-col>
            <button
              type="button"
              class="color-btn red"
              @click="selectedItem.color = '#FF0000'"
            ></button>
            <button
              type="button"
              class="color-btn black"
              @click="selectedItem.color = '#000000'"
            ></button>
            <button
              type="button"
              class="color-btn green"
              @click="selectedItem.color = '#008000'"
            ></button>
          </el-col>
        </div>
        <div v-if="selectedItem.type == 'PRIMARY_FIELDS'">
          <el-form>
            <el-col :span="20">
              <el-form-item label="Select Field">
                <el-select v-model="selectedItem.selected_tag">
                  <el-option
                    v-for="(field, index) of Object.keys(userFields)"
                    :key="index"
                    :label="userFields[field]"
                    :value="field"
                    >{{ userFields[field] }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="Select User">
                <el-select v-model="selectedItem.selected_user">
                  <el-option
                    v-for="(user, index) of documentRecipientsList"
                    :key="index"
                    :label="user.name"
                    :value="user.value"
                  >
                    <span style="float: left">{{ user.name }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ user.email }}</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
        <img
          :src="selectedItem.url"
          v-if="selectedItem.type == 'logo'"
          height="100"
          class="img-fluid"
        />
        <template v-if="selectedItem.type == 'my-signature'">
          <VueSignaturePad
            width="500px"
            height="300px"
            ref="signaturePad"
            :customStyle="{ border: 'black 1px solid' }"
            :options="{
              penColor: selectedItem.color,
            }"
          />

          <el-button
            icon="el-icon-refresh-left"
            title="Undo"
            @click="undo"
          ></el-button>
          <button
            type="button"
            class="color-btn red"
            @click="selectedItem.color = 'red'"
          ></button>
          <button
            type="button"
            class="color-btn black"
            @click="selectedItem.color = 'black'"
          ></button>
          <button
            type="button"
            class="color-btn green"
            @click="selectedItem.color = 'green'"
          ></button>
        </template>
      </div>
      <div v-else-if="selectedItem.type == 'HEADING'">
        <el-form>
          <el-form-item label="Select Tag *">
            <el-select
              v-model="selectedItem.selected_tag"
              no-data-text="Select tag"
            >
              <el-option
                v-for="(tag, index) of ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']"
                :value="tag"
                :key="index"
                :label="tag"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Content">
            <el-input type="textarea" v-model="selectedItem.content"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="selectedItem.type == 'PARAGRAPH'">
        <el-form>
          <el-form-item label="Content">
            <el-input type="textarea" v-model="selectedItem.content"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="selectedItem.section == 'table'">
        <el-form :inline="true">
          <el-form-item label="Add Row Title*">
            <el-input type="text" v-model="selectedItem.latestHeader">
            </el-input>
            <el-button
              class="mt-1"
              type="success"
              icon="el-icon-check"
              size="small"
              @click="addTableHeader(selectedItem.latestHeader)"
              :disabled="
                !selectedItem.latestHeader ||
                  selectedItem.headers.indexOf(selectedItem.latestHeader) != -1
              "
              >Update</el-button
            >
          </el-form-item>
        </el-form>

        <div
          v-if="
            selectedItem &&
              selectedItem.headers &&
              selectedItem.headers.length &&
              !tableElementLoading
          "
        >
          <div v-loading="tableLoading">
            <el-table :data="selectedItem.data" style="width: 100%">
              <el-table-column type="index" width="30"></el-table-column>
              <el-table-column
                v-for="(item, index) of selectedItem.headers"
                :key="index"
              >
                <template slot="header">
                  {{ item }}
                  <span
                    ><el-button
                      type="text"
                      size="mini"
                      class="tomato-red"
                      @click="deleteTableColumn(item)"
                      icon="el-icon-delete"
                    >
                    </el-button
                  ></span>
                </template>
                <template slot-scope="scope">
                  <el-input
                    type="text"
                    v-model="selectedItem.data[scope.$index][item]"
                  >
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column label="Actions" width="100" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    size="mini"
                    plain
                    @click="deleteTableRow(scope.$index)"
                    icon="el-icon-delete"
                  >
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="text-right">
            <el-button
              @click="addTableRow"
              type="primary"
              plain
              class="mt-1"
              size="small"
              >Add A Row</el-button
            >
          </div>
        </div>
      </div>
      <div v-else>
        <el-form label-position="top">
          <el-form-item label="Label *">
            <el-input v-model="selectedItem.label" width="100"></el-input>
          </el-form-item>

          <el-form-item
            label="Placeholder"
            v-if="
              selectedItem.type != 'YES_OR_NO' &&
                selectedItem.type != 'CHECKBOX' &&
                selectedItem.type != 'SIGNATURE'
            "
          >
            <el-input v-model="selectedItem.placeholder" width="100"></el-input>
          </el-form-item>

          <el-form-item
            label="No of Rows"
            v-if="selectedItem.type == 'MULTI_LINE_TEXT'"
          >
            <el-input v-model="selectedItem.rows" width="100"></el-input>
          </el-form-item>

          <el-col :span="22">
            <el-form-item
              v-if="selectedItem.type == 'DATE_SIGNED'"
              label="Singature Field"
              width="100"
            >
              <el-select
                placeholder="Singature Field"
                :filterable="true"
                :default-first-option="true"
                v-model="selectedItem.signature_field"
                :value-key="'key'"
                @change="updateSignatureFilledBy"
              >
                <el-option
                  v-for="(signaturesField, index) of getSignaturesFields"
                  :key="index"
                  :label="signaturesField.label"
                  :value="signaturesField"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="22">
            <el-form-item
              v-if="
                selectedItem.type == 'SELECT' ||
                  selectedItem.type == 'MULTI_SELECT'
              "
              label="Options"
              width="100"
            >
              <el-select
                placeholder="options"
                :clearable="true"
                :filterable="true"
                :multiple="true"
                :allow-create="true"
                :default-first-option="true"
                v-model="selectedItem.options"
              ></el-select>
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="Fields Filled by  *">
              <el-select v-model="selectedItem.filled_by">
                <el-option
                  v-for="(user, index) of documentRecipientsList"
                  :key="index"
                  :label="user.name"
                  :value="user.value"
                  :disabled="user.user_type == 'CC'"
                >
                  <span style="float: left">{{ user.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    user.email
                  }}</span>
                </el-option>

                <!--<el-option
                  :label="'SENDER OR RECEIVER'"
                  :value="'SENDER_OR_RECEIVER'"
                ></el-option>-->
              </el-select>
            </el-form-item>
          </el-col>
          <el-checkbox v-model="selectedItem.required" :label="true"
            >Required Field</el-checkbox
          >
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteElement()">Delete Field</el-button>
        <el-button type="primary" :disabled="checkDisabled()" @click="save()"
          >Save</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="previewVisible"
      top="5vh"
      width="67%"
      title="Preview"
      :destroy-on-close="false"
    >
      <div class="form-holder-preview" ref="page" v-if="previewVisible">
        <div class="form-image-holder-preview">
          <img
            v-if="this.backgroundImage"
            :src="this.backgroundImage"
            class="page-image"
          />
        </div>
        <div class="form-fields-holder-preview">
          <div v-for="(item, index) of this.elements" :key="index">
            <img
              v-if="item.type == 'logo'"
              :src="getLogoUrl"
              :style="getStyle(item)"
            />

            <img
              :height="item.height"
              :width="item.width"
              v-if="item.type == 'my-signature'"
              :src="item.source"
              :style="getStyle(item)"
            />

            <el-input
              :style="getStyle(item)"
              type="text"
              :placeholder="item.placeholder"
              v-if="item.type == 'SINGLE_LINE_TEXT'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-input>

            <el-input
              :style="getStyle(item)"
              type="text"
              :placeholder="item.placeholder"
              v-if="item.type == 'NUMBER'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-input>

            <el-date-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="date"
              :placeholder="item.placeholder"
              v-if="item.type == 'date'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-date-picker>

            <el-select
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              v-if="item.type == 'SELECT'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :disabled="checkFieldAllow(item)"
            >
              <el-option
                v-for="(option, index) of item.options"
                :key="index"
                :value="option"
                :label="option"
              ></el-option>
            </el-select>

            <input
              type="checkbox"
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              v-if="item.type == 'CHECKBOX'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            />
            <el-input
              type="textarea"
              :rows="item.rows"
              :style="getStyle(item)"
              v-if="item.type == 'MULTI_LINE_TEXT'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :disabled="checkFieldAllow(item)"
            />

            <div v-if="item.type == 'signature'" :style="getStyle(item)">
              <a
                v-if="!item.source"
                style="cursor: pointer"
                @click="captureSignature(item, index)"
                :disabled="checkFieldAllow(item)"
              >
                {{ item.label }}
                <i class="el-icon-edit"></i>
              </a>
              <img
                v-if="item.source"
                :src="item.source"
                style="cursor: pointer"
                @click="captureSignature(item, index)"
                height="45"
                class="img-fluid"
              />
            </div>

            <span style="clear: both"></span>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="captureSignatureDialog"
      top="5vh"
      width="30%"
      title="Add signature"
    >
      <template v-if="selectedSignatureItem.type == 'signature'">
        <VueSignaturePad
          height="300px"
          ref="signaturePad"
          :options="{
            penColor: selectedSignatureItem.color,
          }"
        />
        {{ selectedSignatureItem.color }}
        <el-button
          icon="el-icon-refresh-left"
          title="Undo"
          @click="undo"
        ></el-button>
        <button
          type="button"
          class="color-btn red"
          @click="selectedSignatureItem.color = 'red'"
        ></button>
        <button
          type="button"
          class="color-btn black"
          @click="selectedSignatureItem.color = 'black'"
        ></button>
        <button
          type="button"
          class="color-btn green"
          @click="selectedSignatureItem.color = 'green'"
        ></button>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="captureSignatureDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveCapturedSignature()"
          >Add Signature</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="showErrorElementDialog"
      class="outersize-confirm-type-one"
      title="Alert"
    >
      <h3>{{ fieldErrorMessage }}</h3>
      <div v-if="errorElements.length">
        <ul>
          <li v-for="(errorField, index) of errorElements" :key="index">
            <div>
              <p>{{ typeNameMap[errorField.type] }}</p>
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showErrorElementDialog = false">Close</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="showSignErrorElementDialog"
      class="outersize-confirm-type-one"
      title="Alert"
    >
      <h3>{{ fieldErrorMessage }}</h3>
      <div v-if="errorElements.length">
        <ul>
          <li v-for="(errorField, index) of errorElements" :key="index">
            <div>
              <p>{{ typeNameMap[errorField.type] }}</p>
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSignErrorElementDialog = false">Close</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="showServerErrorsDialog"
      top="5vh"
      width="30%"
      title="Errors"
    >
      <ol v-if="getConfigurableDocumentErrors">
        <li
          v-for="(err, index) of Object.values(getConfigurableDocumentErrors)"
          :key="index"
        >
          {{ err }}
        </li>
      </ol>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showServerErrorsDialog = false">Close</el-button>
      </span>
    </el-dialog>

    <!-- v-if="showDocumentUserSettings" -->
    <ConfigureDocumentUsers
      v-if="addDocumentUsers"
      :documentUsers="documentRecipientsList"
      :enforceSignatureOrder="enforceSignatureOrder"
      :allElements="allElements"
      :isSubmit="submitEvent"
      v-on:close="closeDocumentUserSettings"
    ></ConfigureDocumentUsers>
    <ConfigureDocumentExpirationDates
      v-if="showDocumentExpirationDatesSettings"
      :isSubmit="submitEvent"
      :expirationSettings="expirationSettings"
      :allElements="fields"
      :documentUsers="documentUsers"
      :configurableDocumentId="configurableDocumentId"
      v-on:close="closeDocumentExpireDateSettings"
    ></ConfigureDocumentExpirationDates>

    <ConfigureDocumentReceipents
      v-if="addDocumentRecipient"
      :documentUsers="documentRecipientsList"
      :enforceSignatureOrder="enforceSignatureOrder"
      :allElements="allElements"
      v-on:close="closeDocumentUserSettings"
    ></ConfigureDocumentReceipents>
  </section>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import appConfig from "@/config/app";
import { mapGetters } from "vuex";

import offerLetterBuildHelper from "@/mixins/offerLetterBuildHelper";
import ConfigureDocumentExpirationDates from "./ConfigureDocumentExpirationDates";
import ConfigureDocumentUsers from "./ConfigureDocumentUsers";
import ConfigureDocumentReceipents from "./ConfigureDocumentReceipents";

import textToImage from "text-to-image";

export default {
  name:"configureDocuments-ConfigureCompanyTemplatePreview",
  components: {
    VueDraggableResizable,
    ConfigureDocumentUsers,
    ConfigureDocumentExpirationDates,
    ConfigureDocumentReceipents,
  },
  mixins: [offerLetterBuildHelper],

  created() {
    let that = this;
    document.addEventListener("keyup", function(evt) {
      if (evt.keyCode === 46 || evt.keyCode == 8) {
        that.deleteActiveElement();
      }
    });
  },
  data() {
    return {
      tableElementLoading: false,
      activeCollapse: ["1", "2", "3"],
      tableLoading: false,
      elements: [],
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      addItemDataDialogVisible: false,
      selectedItemIndex: null,
      selectedItem: {},
      refreshForm: false,
      selectedSignatureItem: {},
      selectedSignatureItemIndex: -1,
      captureSignatureDialog: false,
      hasBackground: false,
      documentId: null,
      configurableDocumentId: null,
      documentDataLoading: false,
      documentFormData: null,
      documentName: "",
      loading: false,
      backgroundImage: "",
      previewVisible: false,
      activatedItem: null,
      activatedItemIndex: -1,
      fields: [],
      currentFormBuilderWidth: null,
      storedFormBuilderWidth: null,
      checkExistingData: false,
      backgroundImages: [
        "https://devnimblehrms.s3.ap-south-1.amazonaws.com/company-document-backgrounds/60468b2c0a7b7942d5781c36/60468b2c0a7b7942d5781c36-0.png",
      ],
      loadingElements: false,
      selectedTemplateFields: [],
      selectedEntityFields: [],
      templatesUsed: [],
      entitiesUsed: [],
      showErrorElementDialog: false,
      showServerErrorsDialog: false,
      showDocumentUserSettings: false,
      errorElements: [],
      showDocumentExpirationDatesSettings: false,
      fieldErrorMessage: "",
      typeNameMap: {
        logo: "Company Logo",
        SINGLE_LINE_TEXT: "Single Line Input",
        NUMBER: "Number Input",
        MULTI_LINE_TEXT: "Multi line Text",
        SELECT: "DropDown",
        CHECKBOX: "CheckBox",
        DATE: "Date",
        SIGNATURE: "Signature",
        "my-signature": "My Signature",
      },
      documentUsers: [
        {
          email: "",
          first_name: "",
          last_name: "",
          e_signature_required: true,
          value: "SENDER",
          name: "SENDER",
          default: true,
          e_signature_order: 0,
        },
      ],
      enforceSignatureOrder: true,
      signaturesFields: [],
      signatureDates: [],
      signaturesConfig: [],
      submitEvent: false,
      params: {},
      expirationSettings: {
        valid_from_date: {
          is_manual: false,
          manual_type: "",
          signature_user: "",
          signature_user_post_days: "",
          admin_approve_post_days: "",
        },
        valid_to_date: {
          is_manual: false,
          manual_type: "",
          signature_user: "",
          signature_user_post_days: "",
          admin_approve_post_days: "",
        },
      },
      options: [],
      recipientSelection: "",
      list: [],
      recipientsList: [
        "Arian",
        "Arihant",
        "Aristomenis",
        "Azaan",
        "Azedine",
        "Azeem",
        "Azim",
        "Aziz",
        "Azlan",
        "Azzam",
        "Azzedine",
        "Babatunmise",
        "Babur",
        "Benn",
        "Bennett",
        "Benny",
        "Benoit",
        "Iestyn",
        "Ieuan",
        "Igor",
        "Ihtisham",
        "Ijay",
        "Ikechukwu",
        "Ikemsinachukwu",
        "Ilyaas",
        "Ilyas",
        "Iman",
        "Immanuel",
        "Jeswin",
        "Ravin",
        "Ray",
      ],
      documentRecipientsList: [
        {
          email: "",
          first_name: "",
          last_name: "",
          e_signature_required: true,
          value: "SENDER",
          type: "SENDER",
          name: "SENDER",
          default: true,
          e_signature_order: 0,
        },
      ],
      addDocumentRecipient: false,
      addDocumentUsers: false,
      addDocumentRecipientForm: {
        searchRecipient: "",
      },
      addNewRecipient: {
        email: "",
        first_name: "",
        last_name: "",
        e_signature_required: true,
        value: "RECEIVER",
        name: "RECEIVER",
        type: "RECEIVER",
        e_signature_order: 0,
      },
      // for users edit
      selectedUser: null,
      selectedUserIndex: 0,
      contentFields: [
        "PARAGRAPH",
        "HEADING",
        "logo",
        "INITIAL",
        "my-signature",
        "TABLE",
      ],
      userFields: {
        first_name: "First Name",
        last_name: "Last Name",
        company_name: "Company",
        address: "Address",
        phone: "Phone",
        email: "Email",
      },
      saveAction: "",
      isTemplate: false,
      templateTitle: "",
      isTemplateToSave: false,
      isTemplateForm: {
        documentTitle: "",
        agreed: "",
      },

      isShowPreview: false,
      showSignErrorElementDialog: false,
      currentEnabledFieldKey: "",

      createDraftDoc: false,
    };
  },
  computed: {
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentUpdateStatus",
      "getConfigurableDocumentAddStatus",
      "getConfigurableDocumentData",
      "getConfigurableDocumentErrors",
      "getConfigurableDocumentDataById",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getInitialUrl"]),

    ...mapGetters("documents", [
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
    ]),

    getLogoUrl() {
      return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
    },
    getBackgroundImage() {
      return (path) => appConfig.S3_BUCKET_URL + "/" + path;
    },
    allElements() {
      return this.elements;
    },
    getColorByFilledBy() {
      return (item) => {
        let style = "";
        if (item.filled_by || item.selected_user) {
          this.documentRecipientsList.map((user) => {
            if (
              (user.value == item.filled_by ||
                user.value == item.selected_user) &&
              user.color
            ) {
              style = `background: ${user.color + "33"}; border: 1px solid ${
                user.color
              }; color: #000000`;
            }
          });
          return style;
        } else {
          if (item.template_field_filled_by == "SENDER") {
            style = "background:#dcffca; color: #000000";
          } else if (item.entity_field_filled_by == "SENDER") {
            style = "background:#dcffca; color: 000000";
          } else {
            style = "background:#f6d2d5; color:#000000";
          }
        }
        if (item.type != "logo") {
          return style;
        }
        return "";
      };
    },
    hasTemplateFields() {
      let fieldsnOIncludesInTemplate = [
        "SIGNATURE",
        "my-signature",
        "DATE_SIGNED",
      ];

      return fieldsnOIncludesInTemplate.indexOf(this.selectedItem.type) > -1
        ? false
        : true;
    },
    getSignaturesFields() {
      let signaturesFields = this.elements.filter((e) => e.type == "SIGNATURE");
      let duplicateKeyCounter = {};

      return signaturesFields.map((element) => {
        let newKey = this.generateKeyForField(element.key, element.label);
        if (duplicateKeyCounter[newKey]) {
          element.key = newKey + "_" + duplicateKeyCounter[newKey];
          duplicateKeyCounter[newKey]++;
        } else {
          duplicateKeyCounter[newKey] = 1;
          element.key = newKey;
        }
        return element;
      });
    },
  },
  async mounted() {
    this.createDraftDoc = false;
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    this.list = this.recipientsList.map((item) => {
      return { value: `value:${item}`, label: `label:${item}` };
    });
    await this.fetchDocumentDetails();
    // this.addDocumentUsers = true;
    this.isShowPreview = false;
  },
  methods: {
    sendWithSubmit() {
      this.submitEvent = true;
      this.addDocumentUsers = true;
    },
    async saveData() {
      if (this.saveAction == "saveTemplate") {
        await this.saveTemplate(true);
      } else {
        await this.fillAndSend();
      }
      this.isTemplateToSave = false;
    },
    saveTemplateConfirm() {
      this.saveAction = "saveTemplate";
      this.isTemplateToSave = true;
    },
    fillAndSendConfirm() {
      this.saveAction = "previewTemplate";
      this.addDocumentUsers = true;
      // this.isTemplateToSave = true;
    },
    getFieldClass(item) {
      let className = "each-element ";
      className += item.filled_by;
      return className;
    },
    async showPreview() {
      try {
        this.isShowPreview = true;
        await this.saveTemplate(true);
      } catch (err) {
        console.log(err);
      }
    },
    getFilledUserColor(field) {
      let color = "";
      if (field.filled_by) {
        this.documentRecipientsList.map((user) => {
          if (user.value == field.filled_by && user.color) {
            color = user.color;
          }
        });
      }
      return color;
    },
    editReceipentUser(row, index) {
      this.selectedUser = { ...row };
      this.selectedUserIndex = index;
    },
    updateReceipentUser() {
      this.documentRecipientsList = this.documentRecipientsList.map(
        (user, index) => {
          if (index == this.selectedUserIndex) {
            user.first_name = this.selectedUser.first_name;
            user.last_name = this.selectedUser.last_name;
            user.email = this.selectedUser.email;
            user.e_signature_required = this.selectedUser.e_signature_required;
          }
          return user;
        }
      );
      this.selectedUserIndex = null;
      this.selectedUser = null;
    },
    async fetchDocumentDetails() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: this.$route.params.configurable_document_id,
          }
        );

        await this.$store.dispatch("auth/fetchInitialSignature");
        await this.previewDocumentData();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    previewDocumentData() {
      try {
        this.backgroundImages = this.getConfigurableDocumentDataById[
          "background_images"
        ];
        this.documentName = this.getConfigurableDocumentDataById["name"];
        this.pages = this.getConfigurableDocumentDataById.pages;
        // if (this.pages && this.pages.length && this.pages[0].fields) {
        //   this.setFormElements();
        //   // this.elements = this.pages[0].fields;
        // }
        if (this.pages && this.pages.length && this.pages[0].page_base_width) {
          this.storedFormBuilderWidth = this.pages[0].page_base_width;
        }
        this.templateTitle =
          this.getConfigurableDocumentDataById.template_title || "";

        if (
          this.getConfigurableDocumentDataById.is_template &&
          !this.$route.query.is_template
        ) {
          this.isTemplate = this.getConfigurableDocumentDataById.is_template;
        }

        this.documentRecipientsList = this.getConfigurableDocumentDataById.document_users;
        this.enforceSignatureOrder = this.getConfigurableDocumentDataById.enforce_signature_order;
        this.documentRecipientsList = this.documentRecipientsList.map(
          (user) => {
            if (user.name == "RECEIVER") {
              user.color = "#f36371";
            }

            if (user.name == "SENDER") {
              user.color = "#76d845";
            }

            return user;
          }
        );
        setTimeout(() => {
          if (this.pages && this.pages.length && this.pages[0].fields) {
            this.setFormElements();
          }
          this.loadingElements = false;
        }, this.backgroundImages.length * 500);
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.loadingElements = false;
      }
    },
    addReceipentUser() {
      this.documentRecipientsList.push({
        email: this.addNewRecipient.email,
        first_name: this.addNewRecipient.first_name,
        last_name: this.addNewRecipient.last_name,
        e_signature_required: this.addNewRecipient.e_signature_required,
        name: "RECEIVER-" + this.documentRecipientsList.length,
        value: "RECEIVER" + this.documentRecipientsList.length,
        e_signature_order: this.documentRecipientsList.length + 1,
        type: "RECEIVER",
        default: false,
      });

      this.addNewRecipient = {
        email: "",
        first_name: "",
        last_name: "",
        e_signature_required: true,
        value: "RECEIVER",
        name: "RECEIVER",
        type: "RECEIVER",
        default: false,
        e_signature_order: 0,
      };
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    addTableHeader(header) {
      this.tableLoading = true;
      header = header.trim();
      this.selectedItem.headers.push(header);
      this.selectedItem.latestHeader = "";
      if (this.selectedItem.data && this.selectedItem.data.length) {
        let data = [];
        this.selectedItem.data.map((item) => {
          let rowHeader = {};
          this.selectedItem.headers.map((header) => {
            rowHeader[header] = item[header] || "";
          });
          data.push(rowHeader);
        });
        this.selectedItem.data = data;
      }
      this.tableLoading = false;
    },
    addTableRow() {
      this.tableLoading = true;
      let row = {};
      this.selectedItem.headers.forEach((header) => {
        row[header] = "";
      });
      this.selectedItem.data.push(row);
      this.tableLoading = false;
    },
    deleteTableRow(index) {
      this.tableLoading = true;
      this.selectedItem.data.splice(index, 1);
      this.tableLoading = false;
    },
    deleteTableColumn(header) {
      this.tableLoading = true;
      this.selectedItem.headers = this.selectedItem.headers.filter(
        (headerItem) => headerItem != header
      );
      this.selectedItem.data = this.selectedItem.data.map((item) => {
        if (item[header]) {
          delete item[header];
        }
        return item;
      });
      this.tableLoading = false;
    },
    async closeDocumentExpireDateSettings({ expirationSettings }) {
      this.showDocumentExpirationDatesSettings = false;

      if (expirationSettings) {
        this.expirationSettings = expirationSettings;
        await this.saveConfigurableDocument();
      }
    },
    openDocumentExpirationSettings(submit = false) {
      this.showDocumentExpirationDatesSettings = true;
      this.prepareFields();
      this.submitEvent = submit;
    },
    calculateRelativeLeft(x) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
        );
      }
      return x;
    },

    calculateRelativeTop(y) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
        );
      }
      return y;
    },
    calculateRelativeWidth(width) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
        );
      }
      return width;
    },
    getPositionSizeInPercentages({ x, y, height, width }) {
      const formBuilderRef = this.$refs.formBuilder;
      const formBuilderWidth = formBuilderRef.clientWidth;
      const formBuilderHeight = formBuilderRef.clientHeight;
      x = (x / formBuilderWidth) * 100;
      y = (y / formBuilderHeight) * 100;

      height = (height / formBuilderHeight) * 100;
      width = (width / formBuilderWidth) * 100;

      return {
        x,
        y,
        height,
        width,
      };
    },
    setPrevElements() {
      if (
        this.getConfigurableDocumentData &&
        this.getConfigurableDocumentData.length
      ) {
        this.elements = JSON.parse(
          JSON.stringify(this.getConfigurableDocumentData)
        );
      } else {
        this.elements = [];
      }
    },

    setSizeAndPositionForElements(elements) {
      return elements.map((field) => {
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);
        field.width = this.calculateRelativeWidth(field.width);
        field.height = this.calculateRelativeWidth(field.height);
        return field;
      });
    },

    createNewElement() {
      let offset = this.getElementYOffet("logoEle");
      this.elements.push({
        type: "logo",
        section: "display",
        label: "logo",
        height: 100,
        width: 100,
        field_type: "CUSTOM_FIELD",
        url: this.getCompanyProfileData.logo ? this.getLogoUrl : "",
        x: 20,
        y: offset,
      });
    },
    addInitial() {
      let offset = this.getElementYOffet("initialEle");
      this.elements.push({
        type: "INITIAL",
        section: "display",
        label: "Initial",
        height: 50,
        width: 100,
        field_type: "CUSTOM_FIELD",
        value: this.getInitialUrl || "",
        x: 20,
        y: offset,
        text: "",
        color: "",
        font: "",
      });
    },
    addPrimaryField(ref, field) {
      if (this.recipientSelection) {
        let offset = this.getElementYOffet(ref);
        let labelName = this.userFields[field];
        let indx = this.getLastIndexOfElement("PRIMARY_FIELDS");
        indx = indx + 1;

        this.elements.push({
          type: "PRIMARY_FIELDS",
          section: "display",
          label: labelName + " " + indx,
          height: 50,
          width: 100,
          field_type: "CUSTOM_FIELD",
          selected_tag: field,
          content: this.getFieldVal(field, this.recipientSelection),
          selected_user: this.recipientSelection,
          x: 20,
          y: offset,
          index: indx,
        });
      } else {
        this.$message({
          message: "Please Select a Receipent User.",
          type: "warning",
        });
      }
    },
    getFieldVal(field, userType) {
      let fieldVal = "";
      this.documentRecipientsList.map((user) => {
        if (user.value == userType) {
          fieldVal = user[field];
        }
      });
      return fieldVal;
    },
    getLastIndexOfElement(type) {
      let typeEle = this.elements.filter((element) => element.type == type);
      if (typeEle && typeEle.length) {
        return typeEle[typeEle.length - 1] && typeEle[typeEle.length - 1].index
          ? typeEle[typeEle.length - 1].index
          : 0;
      }
      return 0;
    },
    addSingleLineText() {
      let indx = this.getLastIndexOfElement("SINGLE_LINE_TEXT");
      indx = indx + 1;
      let offset = this.getElementYOffet("singleLineTextEle");
      let singleLineTextMetaData = {
        type: "SINGLE_LINE_TEXT",
        section: "input",
        style: "background:#cbe2d8;",
        index: indx,
        label: "Single Line text " + indx,
        placeholder: "Single Line text " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      };

      let positionSize = { x: 20, y: offset, height: 30, width: 150 };

      let singleLineTextElement = {
        ...singleLineTextMetaData,
        ...positionSize,
      };

      this.elements.push(singleLineTextElement);
    },

    addNumber() {
      let indx = this.getLastIndexOfElement("NUMBER");
      indx = indx + 1;
      let offset = this.getElementYOffet("numberEle");
      let numberMetaData = {
        type: "NUMBER",
        section: "input",
        style: "background:#cbe2d8;",
        index: indx,
        label: "Number " + indx,
        placeholder: "Number " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      };

      let positionSize = { x: 20, y: offset, height: 30, width: 150 };

      let numberElement = {
        ...numberMetaData,
        ...positionSize,
      };
      this.elements.push(numberElement);
    },

    addDropDown() {
      let indx = this.getLastIndexOfElement("SELECT");
      indx = indx + 1;
      let offset = this.getElementYOffet("dropdownEle");
      this.elements.push({
        type: "SELECT",
        section: "input",
        style: "background:#cbe2d8;",
        height: 30,
        width: 100,
        options: [],
        x: 20,
        y: offset,
        index: indx,
        label: "Select " + indx,
        placeholder: "Select " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      });
    },

    addMultiSelect() {
      let indx = this.getLastIndexOfElement("MULTI_SELECT");
      indx = indx + 1;
      let offset = this.getElementYOffet("multiSlectedEle");
      this.elements.push({
        type: "MULTI_SELECT",
        section: "input",
        style: "background:#cbe2d8;",
        height: 30,
        width: 130,
        options: [],
        x: 20,
        y: offset,
        index: indx,
        label: "Multi Select " + indx,
        placeholder: "Multi Select " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      });
    },

    addMultilineText() {
      let indx = this.getLastIndexOfElement("MULTI_LINE_TEXT");
      indx = indx + 1;
      let offset = this.getElementYOffet("multiLineTextEle");
      this.elements.push({
        type: "MULTI_LINE_TEXT",
        section: "input",
        style: "background:#cbe2d8;",
        height: 40,
        width: 150,
        options: [],
        x: 20,
        y: offset,
        index: indx,
        label: "Multi Line text " + indx,
        placeholder: "Multi Line text " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      });
    },

    addDateElement() {
      let indx = this.getLastIndexOfElement("DATE");
      indx = indx + 1;
      let offset = this.getElementYOffet("dateEle");
      this.elements.push({
        type: "DATE",
        section: "input",
        style: "background:#cbe2d8;",
        height: 30,
        width: 100,
        x: 20,
        y: offset,
        index: indx,
        label: "DATE " + indx,
        placeholder: "DATE " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      });
    },

    addDateSigned() {
      let indx = this.getLastIndexOfElement("DATE_SIGNED");
      indx = indx + 1;
      let offset = this.getElementYOffet("dateSingedEle");
      this.elements.push({
        type: "DATE_SIGNED",
        section: "input",
        style: "background:#cbe2d8;",
        height: 30,
        width: 120,
        x: 20,
        y: offset,
        field_type: "CUSTOM_FIELD",
        signature_field_id: "",
        signature_field_key: "",
        signature_field: {},
        index: indx,
        label: "Date Signed " + indx,
        placeholder: "Date Signed " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      });
    },

    addCheckbox() {
      let indx = this.getLastIndexOfElement("CHECKBOX");
      indx = indx + 1;
      let offset = this.getElementYOffet("checkboxEle");
      this.elements.push({
        type: "CHECKBOX",
        section: "input",
        style: "background:#cbe2d8;",
        height: 30,
        width: 30,
        x: 20,
        y: offset,
        field_type: "CUSTOM_FIELD",
        index: indx,
        label: "Checkbox " + indx,
        placeholder: "Checkbox " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      });
    },
    addYesOrNo() {
      let indx = this.getLastIndexOfElement("YES_OR_NO");
      indx = indx + 1;
      let offset = this.getElementYOffet("yesOrNoEle");
      this.elements.push({
        type: "YES_OR_NO",
        section: "input",
        style: "background:#cbe2d8;",
        height: 30,
        width: 100,
        x: 20,
        y: offset,
        field_type: "CUSTOM_FIELD",
        index: indx,
        label: "Yes or No " + indx,
        placeholder: "Yes or No " + indx,
        filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
      });
    },
    addMySignature() {
      let offset = this.getElementYOffet("mySignatureEle");
      this.elements.push({
        type: "my-signature",
        section: "display",
        label: "My signature",
        style: "background:#b78686;",
        height: 30,
        width: 100,
        color: "red",
        source: "",
        x: 20,
        y: offset,
        field_type: "CUSTOM_FIELD",
      });
    },

    addSignature() {
      if (this.recipientSelection) {
        let indx = this.getLastIndexOfElement("SIGNATURE");
        indx = indx + 1;
        let offset = this.getElementYOffet("signatureEle");
        this.elements.push({
          type: "SIGNATURE",
          section: "input",
          style: "background:#cbe2d8;",
          label: "Signature " + indx,
          placeholder: "Signature " + indx,
          height: 30,
          field_type: "CUSTOM_FIELD",
          width: 100,
          x: 20,
          y: offset,
          index: indx,
          filled_by: this.recipientSelection || "SENDER_OR_RECEIVER",
        });
      } else {
        this.$message({
          message: "Please Select a Receipent User.",
          type: "warning",
        });
      }
    },

    addHeading() {
      let indx = this.getLastIndexOfElement("HEADING");
      indx = indx + 1;
      let offset = this.getElementYOffet("headingEle");
      this.elements.push({
        type: "HEADING",
        section: "text",
        style: "background:#cbe2d8;",
        label: "Heading " + indx,
        placeholder: "Heading " + indx,
        height: 30,
        field_type: "CUSTOM_FIELD",
        selected_tag: "",
        content: "",
        width: 100,
        x: 20,
        y: offset,
        index: indx,
      });
    },

    addParagraph() {
      let indx = this.getLastIndexOfElement("PARAGRAPH");
      indx = indx + 1;
      let offset = this.getElementYOffet("paragraphEle");
      this.elements.push({
        type: "PARAGRAPH",
        section: "text",
        style: "background:#cbe2d8;",
        label: "Paragraph " + indx,
        placeholder: "Paragraph " + indx,
        height: 30,
        field_type: "CUSTOM_FIELD",
        selected_tag: "p",
        content: "",
        width: 100,
        x: 20,
        y: offset,
        index: indx,
      });
    },

    addTable() {
      let indx = this.getLastIndexOfElement("TABLE");
      indx = indx + 1;
      let offset = this.getElementYOffet("tableEle");
      this.elements.push({
        type: "TABLE",
        section: "table",
        style: "background:#cbe2d8;",
        label: "Table " + indx,
        placeholder: "Table " + indx,
        height: 30,
        field_type: "CUSTOM_FIELD",
        width: 100,
        x: 20,
        y: offset,
        headers: [],
        data: [],
        index: indx,
      });
    },

    setTemplateFields(templateId) {
      let selectedTemplate = this.getAllCompanyTemplatesData.data.find(
        (template) => template._id == templateId
      );

      this.selectedTemplateFields = selectedTemplate.sections[0].fields.filter(
        (field) => field.input_type == this.selectedItem.type
      );
    },

    setEntityFields(entityId) {
      let selectedEntity = this.getAllCompanyEntitiesData.data.find(
        (entity) => entity._id == entityId
      );
      this.selectedEntityFields = [];
      selectedEntity.sections.map((section) => {
        if (section.fields && section.fields.length) {
          this.selectedEntityFields = [
            ...this.selectedEntityFields,
            ...section.fields,
          ];
        }
        return;
      });
      this.selectedEntityFieldId = "";
    },

    setSeletedTemplateField(fieldId) {
      let seletedField = this.selectedTemplateFields.find(
        (field) => field._id == fieldId
      );

      this.$set(this.selectedItem, "template_field_label", seletedField.label);
      this.$set(this.selectedItem, "template_field_id", fieldId);
      this.$set(
        this.selectedItem,
        "template_field_filled_by",
        seletedField.filled_by
      );

      this.$set(this.selectedItem, "entity_id", "");
      this.$set(this.selectedItem, "entity_field_label", "");
      this.$set(this.selectedItem, "entity_field_id", "");
      this.$set(this.selectedItem, "entity_field_filled_by", "");
    },

    setSeletedEntityField(fieldId) {
      let seletedField = this.selectedEntityFields.find(
        (field) => field._id == fieldId
      );

      this.$set(this.selectedItem, "entity_field_label", seletedField.label);
      this.$set(this.selectedItem, "entity_field_id", fieldId);
      this.$set(
        this.selectedItem,
        "entity_field_filled_by",
        seletedField.filled_by
      );
      this.$set(this.selectedItem, "template_id", "");
      this.$set(this.selectedItem, "template_field_label", "");
      this.$set(this.selectedItem, "template_field_id", "");
      this.$set(this.selectedItem, "template_field_filled_by", "");
    },

    async fetchAllCompanyTemplates() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "companyTemplates/getAllCompanyTemplates",
        params
      );
    },

    async fetchAllCompanyEntities() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "companyEntities/getAllCompanyEntities",
        params
      );
    },

    onResize(x, y, width, height, item) {
      item.width = width;
      item.height = height;
      item.x = x;
      item.y = y;
    },
    onDrag(x, y, item) {
      item.x = x;
      item.y = y;
    },

    onDelete(event) {
      console.log(event);
    },

    getElementYOffet(elementRef) {
      let elementOffsetY = 0;
      let pageOffsetPageY = 0;
      if (
        this.$refs[elementRef] &&
        this.$refs[elementRef].getBoundingClientRect()
      ) {
        elementOffsetY = this.$refs[elementRef].getBoundingClientRect().y;
        pageOffsetPageY = this.$refs.formBuilder.getBoundingClientRect().y;
      }
      let offset = elementOffsetY - pageOffsetPageY;

      return offset + this.$refs.formBuilder.scrollTop;
    },

    onActivated(item, index) {
      this.activatedItem = item;
      this.activatedItemIndex = index;
    },
    onDeactivated() {
      this.activatedItem = {};
      this.activatedItemIndex = -1;
    },

    deleteActiveElement() {
      if (this.activatedItem && this.activatedItemIndex > -1) {
        this.elements.splice(this.activatedItemIndex, 1);
      }
    },

    async fetchCompanyDetails() {
      await this.$store.dispatch("company/getCompany");
    },
    async fetchCompanyDocument() {
      this.loading = true;
      this.documentId = this.$route.params.document_id;
      this.configurableDocumentId = this.$route.params.configurable_document_id;
      let params = {
        id: this.documentId,
        name:this.documentName
      };
      await this.$store.dispatch(
        "companyDocuments/fetchCompanyDocumentById",
        params
      );
      await this.$store.dispatch("configurableDocuments/clearErrors");
      if (this.configurableDocumentId) {
        await this.fetchDocumentData();
      } else {
        this.documentName = this.getCompanyDocumentDataById.title;
        this.backgroundImage = this.getBackgroundImage(
          this.getCompanyDocumentDataById.document_background ||
            this.getCompanyDocumentDataById.document_backgrounds[0]
        );

        this.backgroundImages = this.getCompanyDocumentDataById.document_backgrounds.map(
          (e) => {
            return this.getBackgroundImage(e);
          }
        );
      }
      this.loading = false;
    },

    async fetchDocumentDataa() {
      this.documentDataLoading = true;
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocumentById",
        {
          id: this.configurableDocumentId,
        }
      );

      this.documentFormData = JSON.parse(
        JSON.stringify(this.getConfigurableDocumentDataById)
      );

      this.backgroundImage = this.documentFormData.pages[0].background_image;

      if (
        this.documentFormData.pages[0].background_images &&
        this.documentFormData.pages[0].background_images.length
      ) {
        this.backgroundImages = this.documentFormData.pages[0].background_images;
      } else {
        this.backgroundImages = [this.backgroundImage];
      }
      this.documentName = this.documentFormData.name;
      if (this.documentFormData) {
        this.storedFormBuilderWidth = this.documentFormData.pages[0].page_base_width;
      }
      this.documentUsers = this.documentFormData.document_users;

      if (this.documentFormData.document_expiration_settings) {
        this.expirationSettings = this.documentFormData.document_expiration_settings;
      }
      this.enforceSignatureOrder =
        this.documentFormData.enforce_signature_order || false;

      this.loadingElements = true;

      setTimeout(() => {
        this.setFormElements();
        this.loadingElements = false;
      }, this.backgroundImages.length * 500);
      this.documentDataLoading = false;
    },
    setFormElements() {
      this.elements = this.setSizeAndPositionForElements(
        // JSON.parse(JSON.stringify(this.documentFormData.pages[0].fields))
        JSON.parse(JSON.stringify(this.pages[0].fields))
      );
    },

    checkDisabled() {
      if (this.selectedItem.type == "PRIMARY_FIELDS") {
        return false;
      }
      if (this.selectedItem.type == "INITIAL" && this.selectedItem.text) {
        return false;
      }
      if (
        this.selectedItem.type == "TABLE" &&
        this.selectedItem.data &&
        this.selectedItem.headers &&
        this.selectedItem.data.length &&
        this.selectedItem.headers.length
      ) {
        return false;
      }
      if (this.selectedItem.type == "my-signature") {
        return false;
      } else if (this.selectedItem.type == "DATE_SIGNED") {
        return !this.selectedItem.signature_field;
      }

      if (
        this.selectedItem.type == "HEADING" &&
        this.selectedItem.selected_tag &&
        this.selectedItem.content &&
        this.selectedItem.content.trim()
      ) {
        return false;
      }

      if (
        this.selectedItem.type == "PARAGRAPH" &&
        this.selectedItem.content &&
        this.selectedItem.content.trim()
      ) {
        return false;
      }

      return this.selectedItem.label && this.selectedItem.filled_by
        ? false
        : true;
    },

    backToDashCompanyDocuments() {
      this.$router.push({
        name: "edit-configure-template-image",
        params: this.$route.params,
      });
    },
    getStyle(item) {
      let bgColor = this.getColorByFilledBy(item);
      if (item.type == "SIGNATURE") {
        return (
          `position:absolute;left:${item.x}px;top:${item.y}px;cursor:pointer;background:#c2d8de;width:${item.width}px;${bgColor}` +
          (item.source ? `height:50px;` : ``)
        );
      } else if (item.type == "my-signature") {
        return `position:absolute;left:${item.x}px;top:${item.y}px;height:50px;cursor:pointer;${bgColor}`;
      } else if (item.type == "MULTI_LINE_TEXT") {
        return `position:absolute;left:${item.x}px;top:${item.y}px;height:auto;width:${item.width}px;${bgColor}`;
      }
      let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;${bgColor}`;
      return style;
    },
    openPopup(item, index) {
      if (item.type == "INITIAL" && item.url) {
        return "";
      }
      this.activatedItem = {};
      this.activatedItemIndex = -1;
      this.addItemDataDialogVisible = true;
      this.selectedItem = { ...{}, ...this.elements[index] };
      this.selectedItemIndex = index;
    },
    deleteElement() {
      if (this.selectedItemIndex > -1 && this.selectedItem) {
        this.elements.splice(this.selectedItemIndex, 1);
        this.addItemDataDialogVisible = false;
        this.selectedItemIndex = -1;
        this.selectedItem = {};
      }
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },

    updateSignatureFilledBy() {
      this.selectedItem.filled_by = this.selectedItem.signature_field.filled_by;
      this.selectedItem.signature_field_key = this.selectedItem.signature_field.key;
    },

    checkFieldAllow(item) {
      return (
        item.filled_by !== "SENDER" && item.filled_by !== "SENDER_OR_RECEIVER"
      );
    },

    onPreview() {
      this.previewVisible = true;
    },

    saveCapturedSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.selectedSignatureItem.source = data;

        this.elements[
          this.selectedSignatureItemIndex
        ] = this.selectedSignatureItem;
      }
      this.$refs.signaturePad.clearSignature();
      this.selectedSignatureItem = {};
      this.selectedSignatureItemIndex = -1;
      this.captureSignatureDialog = false;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    captureSignature(item, index) {
      this.selectedSignatureItem = item;
      this.selectedSignatureItemIndex = index;
      this.captureSignatureDialog = true;
    },
    generateTextImage(text, color) {
      let url = "";
      textToImage
        .generate(text, {
          textColor: color,
          fontSize: 25,
          lineHeight: 35,
          fontFamily: "Dancing Script",
          maxWidth: 100,
        })
        .then((dataUri) => {
          this.selectedItem.value = dataUri;
        });
      return url;
    },
    async save() {
      if (this.selectedItem.type == "INITIAL" && this.selectedItem.text) {
        await this.generateTextImage(
          this.selectedItem.text,
          this.selectedItem.color
        );
        this.$store.dispatch("auth/addInitialSignature", {
          initial: this.selectedItem.url,
        });
      }

      if (this.selectedItem.type === "my-signature") {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (!isEmpty) {
          this.selectedItem.source = data;
        }
        this.$refs.signaturePad.clearSignature();
      }

      if (this.selectedItem.type === "PRIMARY_FIELDS") {
        this.selectedItem.content = this.getFieldVal(
          this.selectedItem.selected_tag,
          this.selectedItem.selected_user
        );
      }

      this.elements[this.selectedItemIndex] = { ...this.selectedItem };
      this.addItemDataDialogVisible = false;
      this.selectedItem.key = "";
      this.selectedItem.label = "";
      this.selectedItem.filled_by = "";
      this.selectedItem.placeholder = "";
    },

    reloadForm() {
      this.refreshForm = true;
      setTimeout(() => {
        this.refreshForm = false;
      });
      this.$store.dispatch(
        "configurableDocuments/saveCurrentDocumentFields",
        this.elements
      );
    },
    async saveTemplate(template = null) {
      try {
        this.loading = true;
        let validateData = await this.validateForm();
        if (validateData) {
          this.params = this.prepareFields();
          if (template) {
            this.params.is_template = true;
          }

          await this.updateForm(this.params);

          // this.openDocumentUserSettings(true);
        } else {
          this.showErrorElementDialog = true;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Error at saving form",
        });
      }
    },

    async saveUpdateData() {
      // if (this.params.id) {
      await this.updateForm(this.params);
      // } else {
      //   await this.addNewForm(this.params);
      // }
    },
    validateForm() {
      this.errorElements = [];
      if (this.elements.length > 0) {
        this.errorElements = this.elements.filter((e) => {
          return (
            !e.template_field_id &&
            !e.entity_field_id &&
            e.type !== "logo" &&
            e.type !== "my-signature" &&
            e.type !== "HEADING" &&
            e.type !== "PARAGRAPH" &&
            e.section !== "table" &&
            e.type != "INITIAL" &&
            e.type != "PRIMARY_FIELDS" &&
            (!e.label || e.label.length == 0 || !e.filled_by)
          );
        });

        if (this.errorElements.length) {
          this.fieldErrorMessage = "Please config below Fields";
          return false;
        } else {
          return true;
        }
      } else {
        this.fieldErrorMessage =
          "There was No fields. Please place some fields";
        return false;
      }
    },
    async addNewForm(params) {
      await this.$store.dispatch(
        "configurableDocuments/addConfigurableDocument",
        params
      );
      if (this.getConfigurableDocumentAddStatus) {
        this.$notify.success({
          title: "Success",
          message: "Form saved successfully",
        });

        this.$router.push({
          path: "/templates",
        });
      } else {
        if (this.getConfigurableDocumentErrors) {
          this.showServerErrorsDialog = true;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error at saving form",
          });
        }
      }
    },

    async updateForm(params) {
      try {
        await this.$store.dispatch(
          "configurableDocuments/updateConfigurableDocument",
          params
        );
        if (this.getConfigurableDocumentUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Form saved successfully",
          });
          if (this.createDraftDoc) {
            await this.createDraft();
          }
          // await this.fetchDocumentDetails();
          if (this.isShowPreview) {
            this.$router.push({
              name: "edit-configure-template-image-preview",
              params: this.$route.params,
            });
          }
        } else {
          this.isShowPreview = false;
          if (this.getConfigurableDocumentErrors) {
            this.showServerErrorsDialog = true;
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving form",
            });
          }
        }
      } catch (err) {
        this.isShowPreview = false;
        console.log(err);
      }
    },

    prepareFields() {
      let signUsers = this.elements
        .filter((element) => element.type == "SIGNATURE")
        .flatMap((element) => element.filled_by);
      let users = this.documentRecipientsList.map((user, index) => {
        user.e_signature_order = index;
        if (signUsers.indexOf(user.value) != -1) {
          user.e_signature_required = true;
        } else {
          user.is_cc = true;
          user.e_signature_required = false;
        }

        if (user.contact_type && user.contact_type._id) {
          user.contact_type = user.contact_type._id;
        }
        return user;
      });
            let inOrderFields = this.getFieldsFromElements();
      inOrderFields.sort((a, b) => {
        return a.y - b.y;
      });
      let data = {
        id: this.$route.params.configurable_document_id || undefined,
        name: this.documentName,
        type: "CUSTOM",
        configure_type: "DYNAMIC",
        company_document_id: this.$route.params.document_id,
        background_images: this.backgroundImages,
        document_users: users,
        document_expiration_settings: this.expirationSettings,
        enforce_signature_order: this.enforceSignatureOrder || false,
        is_template: this.isTemplate,
        template_title: this.documentName,
        pages: [
          {
            page_no: 1,
            background: this.hasBackground,
            background_image: this.backgroundImage,
            background_images: this.backgroundImages,
            style:
              "position: relative;width: 1240px;border: 1px solid #ccc;margin: 1em;max-height: 90vh;overflow: scroll;padding: 20px;",
            fields: inOrderFields,
            page_base_width: this.currentFormBuilderWidth,
            templates_used: Array.from(new Set(this.templatesUsed)),
            entities_used: Array.from(new Set(this.entitiesUsed)),
          },
        ],
      };

      if (
        this.getConfigurableDocumentDataById.description &&
        this.getConfigurableDocumentDataById.description.length
      ) {
        data.description = this.getConfigurableDocumentDataById.description;
      }

      return data;
    },
    getFieldsFromElements() {
      let duplicateKeyCounter = {};
      this.templatesUsed = [];
      this.entitiesUsed = [];
      return this.elements.map((element, index) => {
        element.sno = index + 1;

        let newKey = this.generateKeyForField(element.key, element.label);
        if (duplicateKeyCounter[newKey]) {
          element.key = newKey + "_" + duplicateKeyCounter[newKey];
          duplicateKeyCounter[newKey]++;
        } else {
          duplicateKeyCounter[newKey] = 1;
          element.key = newKey;
        }

        if (element.type != "my-signature" && element.type != "INITIAL") {
          element.value = "";
          element.source = "";
        }

        if (element.type == "HEADING") {
          element.value = element.content;
          element.source = element.selected_tag;
        }

        if (element.type == "PARAGRAPH") {
          element.value = element.content;
          element.source = "";
        }
         if (element.type == "HORIZONTAL_LINE") {
          element.value = element.content;
          element.source = ""
        }
        return element;
      });
    },
    getKeyForTemplateField(templateId, fieldId) {
      let selectedTemplate = this.getAllCompanyTemplatesData.data.find(
        (template) => template._id == templateId
      );

      let selectedTemplateField = selectedTemplate.sections[0].fields.find(
        (field) => field._id == fieldId
      );
      return (
        selectedTemplate.name.split(" ").join("") +
        "#" +
        selectedTemplateField.key
      );
    },

    getKeyForEntityField(entityId, fieldId) {
      let selectedEntity = this.getAllCompanyEntitiesData.data.find(
        (entity) => entity._id == entityId
      );

      let selectedEntityField = {};
      let fields = [];
      if (
        selectedEntity &&
        selectedEntity.sections &&
        selectedEntity.sections.length
      ) {
        selectedEntity.sections.map((section) => {
          if (section.fields && section.fields.length) {
            fields = section.fields;
            for (let index = 0; index < fields.length; index++) {
              if (fields[index]._id == fieldId) {
                selectedEntityField = fields[index];
              }
            }
            return;
          }
        });
        return (
          selectedEntity.name.split(" ").join("") +
          "#" +
          selectedEntityField.key
        );
      } else {
        return "";
      }
    },

    generateKeyForField(previousKey, label) {
      if (previousKey) {
        return previousKey;
      }

      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel
        .toLowerCase()
        .split(" ")
        .join("_");

      return parsedLabel;
    },

    openDocumentUserSettings() {
      this.saveUpdateData();
    },

    closeDocumentUserSettings({
      users,
      enforceSignatureOrder,
      signaturesConfig,
      submit,
    }) {
      if (users && users.length) {
        this.documentRecipientsList = users;
      }
      if (signaturesConfig && signaturesConfig.length) {
        this.signaturesConfig = signaturesConfig;
      }
      this.submitEvent = false;
      this.showDocumentUserSettings = false;
      this.addDocumentRecipient = false;
      this.addDocumentUsers = false;
      this.addDocumentRecipient = false;
      this.enforceSignatureOrder = enforceSignatureOrder;
      if (this.saveAction == "previewTemplate") {
        this.isTemplateToSave = true;
        this.saveAction = "";
      }
      if (submit) {
        this.documentSend();
      }
    },
    async fillAndSend() {
      this.loading = true;
      this.createDraftDoc = true;
      await this.saveTemplate();
      // await this.createDraft();
      this.createDraftDoc = false;
      this.loading = false;
    },
    async documentSend() {
      this.loading = true;
      await this.saveTemplate();
      let checkSign = await this.checkSignUser();
      if (checkSign) {
        this.$router.push({
          name: "send-template-mail",
          params: {
            id: this.$route.params.configurable_document_id,
          },
        });
      }
      // await this.createSendDoc();
      this.loading = false;
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    async createSendDoc() {
      try {
        let checkSign = await this.checkSignUser();
        if (checkSign) {
          this.loading = true;
          let documentData = this.prepareDocumentData();
          let companayDocumentData = this.prepareFields();
          let timeZone = this.getTimeZone();
          let params = {
            document_template_id: this.$route.params.document_id,
            document_users: this.documentRecipientsList,
            enforce_signature_order: true,
            sender_time_zone:timeZone,
            document_data: documentData,
            companayDocumentData: companayDocumentData,
          };
          await this.$store.dispatch("documents/createSentDocument", params);

          if (this.getDraftDocumentCreateStatus) {
            let draftId = this.getDraftDocumentCreateData.data._id;
            this.$router.push({
              name: "employee-documents-custom-document",
              params: {
                employee_document_id: draftId,
              },
            });
          }
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async createDraft() {
      try {
        this.loading = true;
        let checkSign = await this.checkSignUser();
        if (checkSign) {
          let documentData = this.prepareDocumentData();
          let companayDocumentData = this.prepareFields();
          let params = {
            document_template_id: this.$route.params.document_id,
            document_users: this.documentRecipientsList,
            enforce_signature_order: true,
            document_data: documentData,
            companayDocumentData: companayDocumentData,
            is_document: false,
          };

          await this.$store.dispatch("documents/createDraftDocument", params);

          if (this.getDraftDocumentCreateStatus) {
            let draftId = this.getDraftDocumentCreateData.data._id;
            this.$router.push({
              name: "employee-documents-custom-document-edit",
              params: {
                employee_document_id: draftId,
              },
            });
          }
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    checkSignUser() {
      let userCount = 0;
      let signUsers = [];
      signUsers = this.elements
        .filter((element) => element.type == "SIGNATURE")
        .flatMap((element) => element.filled_by);
      userCount = this.documentRecipientsList.filter((user) => {
        if (user.user_type == "SIGNER" && signUsers.indexOf(user.value) == -1) {
          return user;
        }
      });
      if (userCount.length) {
        this.fieldErrorMessage =
          "Signature was not selected for the SIGNER Users";
        this.showSignErrorElementDialog = true;
      }
      return userCount.length ? false : true;
    },
    prepareDocumentData() {
      let data = {};
      this.elements.forEach((element) => {
        if (element.content) {
          data[element.key] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else if(element.files && element.files.length) {
          data[element.key] = element.files;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
    deleteRow(index) {
      this.documentRecipientsList.splice(index, 1);
    },
    removeHashesFromKey(str) {
      return str.replace(/#/g, "");
    },
  },

  watch: {
    async "$route.params.configurable_document_id"() {
      window.location.reload();
    },
    async "$route.name"() {
      window.location.reload();
    },
  },
  beforeDestroy() {
    this.$store.commit(
    "configurableDocuments/setConfigurableDocumentUpdateStatus",null,{root:true})
    this.$store.commit("configurableDocuments/setConfigurableDocumentAddStatus",null,{root:true})
    this.$store.commit("configurableDocuments/setConfigurableDocumentData",null,{root:true})
    this.$store.commit("configurableDocuments/setConfigurableDocumentErrors",null,{root:true})
    //this.$store.commit("configurableDocuments/setConfigurableDocumentDataById",null,{root:true})
    this.$store.commit("documents/setDraftDocumentCreateStatus",null,{root:true})
    this.$store.commit("documents/setDraftDocumentCreateData",null,
     {root: true,}
    );
  },
};
</script>

<style lang="scss" scoped>
.form-builder {
  width: 950px;
  margin: 1.5em auto;
  max-height: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  .vdr {
    border: 1px solid rgba(#f36371, 0.5);
    padding: 2px 5px;
  }
  .form-image-holder {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .form-fields-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .each-element img {
      width: 100%;
    }
  }
}
#drag-template-background {
  .img-fluid {
    height: auto !important;
  }
  .inner-navbar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 100;
    background-color: #ffffff;
    border: 1px solid #efefef;
    margin-bottom: 0 !important;
    & > .d-flex {
      gap: 10px;
      .back-btn {
        height: inherit;
      }
    }
    .left-block {
      .el-input__inner {
        min-width: 250px;
        max-width: 300px;
        background: #fff;
        height: 47px;
      }
    }
  }
  .form-fields-set {
    z-index: 99;
    height: calc(100vh - 85px);
    overflow-y: scroll;
    background-color: #ffffff;
    padding: 1.25em 1em;
    border-radius: 0px;
  }
  .document-editor-container {
    td {
      padding-top: 0;
      vertical-align: top;
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 80%;
      }
    }
    .inputs-list {
      background: #fff;
      padding: 15px;
      box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
        0 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      .el-button {
        border: none;
        background: rgba(236, 245, 255, 0.7);
        &:hover {
          background: rgba(236, 245, 255, 1);
        }
      }
      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
  .form-image {
    max-width: 770px;
    min-height: 1000px;
    background-color: #e6dcdc;
  }
  .el-input__inner {
    display: inline !important;
  }

  .form-holder {
    width: 100%;
    position: relative;
    .el-input__inner,
    .el-textarea__inner {
      height: 100% !important;
      min-height: 30px !important;
      line-height: inherit !important;
    }
    input[type="checkbox"] {
      margin: 0 !important;
    }

    // .draggable.resizable {
    //   position: absolute;
    //   z-index: 100;
    // }
  }

  .sample-form1 {
    position: relative;
    width: 100%;
    max-height: 91vh;
    overflow: scroll;
    border: 1px solid #ccc;
    margin-left: 20px;
    margin-bottom: 45px;

    ///
    .each-page {
      position: relative;
      width: 900px;
      display: block;
      height: 800px;
      overflow-y: auto;
      .form-image {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
      }
      .form-fields {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
        z-index: 100;
      }
      .page-image {
        width: 850px;
        margin: 0 auto;
      }
    }

    // .draggable.resizable {
    //   position: absolute;
    //   z-index: 100;
    // }
  }

  .color-btn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;
    &.red {
      background-color: red;
    }
    &.black {
      background-color: black;
    }

    &.green {
      background-color: green;
    }
  }

  // Fields
  .scrollable-container {
    .fieldSet__box {
      margin-bottom: 1em;
      // padding-bottom: 1em;
      // border-bottom: 1px solid #efefef;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      .field-element {
        position: relative;
        padding: 0.5em 1em;
        width: 100%;
        display: grid;
        grid-template-columns: 35px 1fr;
        align-items: center;
        font-size: 0.9em;
        white-space: normal;
        line-height: 1;
        background-color: #f9f9f9;
        border: 1px solid #f1f1f1;
        height: 55px;
        color: #606266;
        border-radius: 3px;
        &:focus,
        &:hover {
          color: #f36371;
          border-color: #fdcfd4;
          background-color: #ffe9ec;
        }
        img {
          max-height: 25px;
          padding-right: 10px;
        }
        .name {
          padding-left: 10px;
          line-height: 1.3;
          font-size: 0.9rem;
          text-align: left;
          font-weight: 500;
          i {
            width: 100%;
            font-size: 1em;
            padding-bottom: 0.5em;
          }
        }
      }
    }
  }
  // .draggable {
  //   height: auto !important;
  //   background: rgba(236, 245, 255, 0.48);
  // }
}
</style>

<style lang="scss">
.document-editor-container {
  position: relative;
  height: calc(100vh - 62px);
  overflow: hidden;
  .el-scrollbar {
    height: calc(100vh - 62px);
  }
}
.configure-document {
  .el-collapse-item__header {
    &.is-active {
      background-color: #ffffff;
    }
  }
  .el-collapse-item__content {
    padding-bottom: 10px;
  }
  padding-top: 0 !important;
  background-color: #f8f9fa;
  .handle {
    width: 8px;
    height: 8px;
    background: inherit;
    border: 1px solid inherit;
  }
  .each-element.my-active-class {
    border: 1px dashed #f36371 !important;
  }
  .form-title {
    .el-input {
      position: relative;
      input {
        padding: 0;
        line-height: 1;
        background-color: rgba(255, 255, 255, 0.35);
        border: none;
        font-size: 1.5em;
        height: 60px !important;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-bottom: 0;
        color: #222222;
        box-shadow: none;
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 20px;
        width: 10px;
        //background-image: url(/img/hover-dots.ecfb2fa2.svg);
        background-size: 10px 20px;
        background-repeat: no-repeat;
        background-position: center;
        left: 0px;
        opacity: 0;
        z-index: 10;
        transition: 0.3s all ease-in-out;
        -webkit-transition: 0.3s all ease-in-out;
      }
    }
  }

  .right-block {
    height: inherit;
    display: flex;
    align-items: center;
  }
}
.fields-dialog {
  .el-select {
    width: 100%;
  }
}
</style>
